import classNames from 'classnames'
import Button from '../Button'
import { XIcon } from '@heroicons/react/solid'
import BottomHalfMenu from './BottomHalfMenu'

type ShortScreenMenuDrawerProps = {
  isExpanded?: boolean
  isOpen?: boolean
  setIsOpen?: (isOpen: boolean) => void
  isNotificationDrawerOpen?: boolean
  setIsNotificationDrawerOpen?: (isOpen: boolean) => void
}

export const ShortScreenMenuDrawer = ({
  isExpanded,
  isOpen,
  setIsOpen,
  isNotificationDrawerOpen,
  setIsNotificationDrawerOpen,
}: ShortScreenMenuDrawerProps) => {
  return (
    <div
      data-testid="short-screen-menu-drawer"
      aria-expanded={isOpen}
      className={classNames(
        isExpanded ? 'left-72' : 'left-[5.5rem]',
        'absolute z-40 h-full w-[396px] flex-col justify-between',
        'border-atst bg-snowtrooper duration-200 ease-in',
        isOpen ? 'translate-x-0 border-r-2' : '-translate-x-[396px]'
      )}
    >
      <div className="flex items-center justify-between border-b-2 p-4">
        <p className="text-2xl pl-2 font-semibold">Menu</p>
        <Button
          className="p-1"
          iconOnly
          variant="bareMinimal"
          onClick={() => setIsOpen?.(false)}
        >
          <XIcon className="h-4 w-4" />
        </Button>
      </div>
      <div className="p-3">
        <BottomHalfMenu
          expanded={true}
          isNotificationDrawerOpen={isNotificationDrawerOpen}
          setIsNotificationDrawerOpen={setIsNotificationDrawerOpen}
        />
      </div>
    </div>
  )
}
