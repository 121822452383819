import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import IconButton from '../IconButton'
import AppSwitcher from './AppSwitcher'
import BottomHalf from './BottomHalf'
import { getAppName } from './utils'
import { useState } from 'react'
import DesktopNotificationDrawerWrapper from './Notifications/DesktopNotificationDrawerWrapper'
import { ShortScreenMenuDrawer } from './ShortScreenMenuDrawer'
import { NovuProvider } from '@novu/notification-center'

export const setNovuProps = () => {
  if (window.ENV.NOVU_API_URL && window.ENV.NOVU_SOCKET_URL) {
    return {
      backendUrl: window.ENV.NOVU_API_URL,
      socketUrl: window.ENV.NOVU_SOCKET_URL,
    }
  }
  return {}
}

export default function SideNavigation() {
  const [isExpanded, setIsExpanded] = useLocalStorage(
    'sidenav.isExpanded',
    false
  )
  const [isShortScreenMenuOpen, setIsShortScreenMenuOpen] =
    useState<boolean>(false)
  const location = useLocation()
  const appName = getAppName(location.pathname)
  const [isNotificationDrawerOpen, setIsNotificationDrawerOpen] =
    useState<boolean>(false)

  const children = (
    <>
      <div className="flex h-full items-stretch">
        <div
          data-testid="side-navigation"
          aria-expanded={isExpanded}
          className={classNames(
            'flex h-full flex-col justify-between border-r-2',
            'z-60 border-atst bg-snowtrooper !px-3 duration-200 ease-in',
            isExpanded ? 'w-72' : 'w-[5.5rem]'
          )}
        >
          <div className="h-full !pt-3">
            {appName && (
              <AppSwitcher selected={appName} expanded={isExpanded} />
            )}
          </div>
          <BottomHalf
            expanded={isExpanded}
            setIsNotificationDrawerOpen={setIsNotificationDrawerOpen}
            isNotificationDrawerOpen={isNotificationDrawerOpen}
            isShortScreenMenuOpen={isShortScreenMenuOpen}
            setIsShortScreenMenuOpen={setIsShortScreenMenuOpen}
          />
        </div>
        {window.FEATURE_FLAGS.notifications && window.ENV.NOVU_APP_ID ? (
          <DesktopNotificationDrawerWrapper
            isExpanded={isExpanded}
            isOpen={isNotificationDrawerOpen}
            setIsOpen={setIsNotificationDrawerOpen}
          />
        ) : null}
        <ShortScreenMenuDrawer
          isExpanded={isExpanded}
          isOpen={isShortScreenMenuOpen}
          setIsOpen={setIsShortScreenMenuOpen}
          isNotificationDrawerOpen={isNotificationDrawerOpen}
          setIsNotificationDrawerOpen={setIsNotificationDrawerOpen}
        />
      </div>
      <IconButton
        hideRing
        description="expand sidebar"
        iconOnly
        variant="bareMinimal"
        size="small"
        className={classNames(
          'fixed bottom-[0.6rem] h-16 !w-6 duration-200 ease-in hover:!bg-atst',
          isExpanded
            ? 'left-[16.4rem] !rounded-r-none !bg-atst z-60'
            : 'left-[5.3rem] !rounded-l-none border-y-atst border-r-atst !bg-snowtrooper z-50'
        )}
        icon={isExpanded ? ChevronLeftIcon : ChevronRightIcon}
        onClick={() => setIsExpanded(!isExpanded)}
      />
    </>
  )

  return (
    <>
      {window.FEATURE_FLAGS.notifications && window.ENV.NOVU_APP_ID ? (
        <NovuProvider
          data-testid="novu-provider"
          subscriberId={window.CURRENT_USER.id.toString()}
          applicationIdentifier={window.ENV.NOVU_APP_ID}
          {...setNovuProps()}
          initialFetchingStrategy={{
            fetchUnseenCount: true,
            fetchNotifications: true,
          }}
        >
          {children}
        </NovuProvider>
      ) : (
        children
      )}
    </>
  )
}
