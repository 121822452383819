import { DateTime } from 'luxon'
import AccountsIcon from '../../icons/AccountsIcon'
import GraphIcon from '../../icons/GraphIcon'
import CalendarIcon from '../../icons/CalendarIcon'
import MedalIcon from '../../icons/MedalIcon'
import ChecklistIcon from '../../icons/ChecklistIcon'
import RecruitsIcon from '../../icons/RecruitsIcon'
import StatsIcon from '../../icons/StatsIcon'
import TeamsIcon from '../../icons/TeamsIcon'
import OrganizationIcon from '../../icons/OrganizationKITIcon'
import KitHomeIcon from '../../icons/KitHomeIcon'
import {
  canViewAccountMap,
  canViewAdminPage,
  canViewCompetitions,
  canViewCrew,
  canViewLumioStore,
  canViewMedia,
  canViewOpsPage,
  canViewWorkdayElements,
  hasRole,
  Role,
} from '../../utils/currentUser'
import CheckMarkIcon from '../../icons/CheckMark'
import SalesRabbitIcon from '../../icons/SalesRabbitIcon'
import LumioDashboardIcon from '../../icons/LumioDashboardIcon'
import { CORPORATE_TZ } from '../../views/Leaderboard/corporate_tz'
import AnnouncementsIcon from '../../icons/AnnouncementsIcon'
import CompassMapIcon from '../../icons/CompassMapIcon'
import GraduateHat from '../../icons/GraduateHat'
import CoursesIcon from '../../icons/CoursesIcon'
import LibraryIcon from '../../icons/LibraryIcon'
import LHXIcon from '../../icons/LHXIcon'
import CompensationIcons from '../../icons/CompensationIcon'
import TeslaIcon from '../../icons/TeslaIcon'
import SettingsIcon from '../../icons/SettingsIcon'
import SellingStatesIcon from '../../icons/SellingStatesIcon'
import UtilityCompaniesIcon from '../../icons/UtilityCompaniesIcon'
import { DocumentIcon } from '../../icons'

const linkClasses = 'min-h-[3rem] min-w-[3rem] h-12 w-12 stroke-vader p-2.5'

export function isAppVisible(app: ApplicationName) {
  const visibleIfFn = LINKS[app].visibleIf
  return !visibleIfFn || visibleIfFn()
}

export function isItemVisible(item: AppLinkNestedPage) {
  const visibleIfFn = item.visibleIf
  return !visibleIfFn || visibleIfFn()
}

export function itemUrlMatchesPath(
  appItem: AppLinkNestedPage,
  pathname: string
) {
  if (appItem.match) return !!pathname.match(appItem.match)

  return appItem.url.includes(pathname)
}

function appUrlMatches(app: AppLinkPage, pathname: string) {
  return !!pathname.match(app.match())
}

export function getAppName(pathname: string) {
  let appName =
    APPS.find(
      (appName) =>
        isAppVisible(appName) && appUrlMatches(LINKS[appName], pathname)
    ) ?? 'Toolkit'

  // these pages are independent of the app from which they are accessed
  if (pathname.match(/users\/\d+|onboarding\/\d+/)) {
    appName =
      (window.localStorage.getItem('sidenav.appName') as ApplicationName) ||
      appName
  }

  window.localStorage.setItem('sidenav.appName', appName)
  return appName
}

export const defaultFiltersStats = () => {
  const currentDayMT = DateTime.local({ zone: CORPORATE_TZ })
  const statsAfter = currentDayMT.startOf('day').toMillis()
  const statsBefore = currentDayMT.endOf('day').toMillis()
  const defaultOrg = 1
  return `/leaderboard?underOrgId=${defaultOrg}&statsAfter=${statsAfter}&statsBefore=${statsBefore}`
}

export const APPS = [
  'Toolkit',
  'Solflow',
  'Perform',
  'Crew',
  'Teams',
  'Training',
  'Admin',
  'Ops',
] as const
export type ApplicationName = (typeof APPS)[number]

export const APPICONS = {
  Toolkit: <LHXIcon />,
  Solflow: 'S',
  Perform: 'P',
  Crew: 'C',
  Teams: 'T',
  Training: <GraduateHat className="fill-none" />,
  DailyBrief: 'D',
  Admin: <SettingsIcon className="fill-none" />,
  Ops: 'O',
}

type AppLink = Record<ApplicationName, AppLinkPage>
type AppLinkPage = {
  landingPage: string
  items: AppLinkNestedPage[]
  visibleIf?: () => boolean
  match: () => RegExp
}
type AppLinkNestedPage = {
  url: string
  match?: RegExp
  label: string
  icon?: React.ReactElement
  visibleIf?: () => boolean
}

export const LINKS: AppLink = {
  Toolkit: {
    match: () => /dashboard|announcements/,
    landingPage: '/dashboard',
    items: [
      {
        url: '/dashboard',
        label: 'Home',
        icon: <KitHomeIcon className={linkClasses} />,
      },
      {
        url: '/announcements',
        label: 'Announcements',
        icon: <AnnouncementsIcon className={linkClasses} />,
      },
    ],
  },
  Solflow: {
    match: () => {
      const pagesToMatch = [
        'account',
        'calendar',
        'queues',
        'tasks',
        'pipeline',
      ]

      if (canViewAccountMap()) {
        pagesToMatch.push('map')
      }

      const regexPattern = pagesToMatch.join('|')

      return new RegExp(`${regexPattern}`)
    },
    landingPage: '/accounts',
    items: [
      {
        url: '/accounts',
        match: /\/accounts|\/pipeline/,
        label: 'Accounts',
        icon: <AccountsIcon className={linkClasses} />,
      },
      {
        url: '/calendar',
        label: 'Calendar',
        icon: <CalendarIcon className={linkClasses} />,
      },
      {
        url: '/queues',
        label: 'Queues',
        icon: <ChecklistIcon className={linkClasses} />,
      },
      {
        url: '/tasks',
        label: 'Tasks',
        icon: <CheckMarkIcon className={linkClasses} />,
      },
      {
        url: '/map',
        label: 'Map',
        icon: <CompassMapIcon className={linkClasses} />,
        visibleIf: () => canViewAccountMap(),
      },
    ],
  },
  Perform: {
    landingPage: canViewCompetitions()
      ? '/competitions'
      : defaultFiltersStats(),
    match: () => {
      const pagesToMatch = [
        'daily-brief',
        'leaderboard',
        'compensation',
        'race-to-the-top',
      ]

      if (canViewCompetitions()) {
        pagesToMatch.push('competitions')
      }

      const regexPattern = pagesToMatch.join('|')

      return new RegExp(`${regexPattern}`)
    },
    items: [
      {
        url: '/daily-brief',
        label: 'Daily Brief',
        icon: <StatsIcon className={linkClasses} />,
      },
      {
        url: defaultFiltersStats(),
        label: 'Leaderboard',
        icon: <GraphIcon className={linkClasses} />,
      },
      {
        url: '/competitions',
        label: 'Competitions',
        icon: (
          <MedalIcon className="h-12 min-h-[3rem] w-12 min-w-[3rem] p-2.5" />
        ),
        match: /competitions/,
        visibleIf: () => canViewCompetitions() && hasRole(Role.SalesRep),
      },
      ...(window.FEATURE_FLAGS.tableau_compensation
        ? [
            {
              url: '/compensation',
              label: 'Quarterly Bonus',
              icon: <CompensationIcons className={linkClasses} />,
            },
          ]
        : []),
      ...(window.FEATURE_FLAGS.tableau_top_to_the_race
        ? [
            {
              url: '/race-to-the-top',
              label: 'Race to the Top',
              icon: <TeslaIcon className={linkClasses} />,
            },
          ]
        : []),
    ],
  },
  Crew: {
    landingPage: '/recruit',
    visibleIf: () => canViewCrew(),
    match: () => {
      const pagesToMatch = []
      if (canViewCrew()) {
        pagesToMatch.push(
          'recruit',
          'onboarding',
          'learning',
          'recruit/training'
        )
      }

      const regexPattern = pagesToMatch.join('|')

      return new RegExp(`${regexPattern}`)
    },
    items: [
      {
        url: '/recruit',
        label: 'Recruit',
        icon: <RecruitsIcon className={linkClasses} />,
        visibleIf: () => canViewCrew(),
      },
    ],
  },
  Teams: {
    landingPage: '/teams',
    match: () => /teams|orgs|userList|users/,
    items: [
      {
        url: '/teams',
        label: 'Teams',
        icon: <TeamsIcon className={linkClasses} />,
      },
      {
        url: '/orgs',
        label: 'Orgs',
        icon: <OrganizationIcon className={linkClasses} />,
      },
    ],
  },
  Training: {
    landingPage: '/courses',
    match: () => /courses|library/,
    visibleIf: () => canViewMedia(),
    items: [
      {
        url: '/courses',
        label: 'Courses',
        icon: <CoursesIcon className={linkClasses} />,
        visibleIf: () => canViewMedia(),
      },
      {
        url: '/library',
        label: 'Library',
        icon: <LibraryIcon className={linkClasses} />,
        visibleIf: () => canViewMedia(),
      },
    ],
  },
  Admin: {
    landingPage: '/admin/selling-states',
    match: () => /selling-states|utility-companies/,
    visibleIf: () => canViewAdminPage(),
    items: [
      {
        url: '/admin/selling-states',
        label: 'Selling States',
        icon: <SellingStatesIcon className={linkClasses} />,
        visibleIf: () => canViewAdminPage(),
      },
      {
        url: '/admin/utility-companies',
        label: 'Utilities',
        icon: <UtilityCompaniesIcon className={linkClasses} />,
        visibleIf: () => canViewAdminPage(),
      },
    ],
  },
  Ops: {
    landingPage: '/ops/forms',
    match: () => /ops/,
    visibleIf: () => canViewOpsPage() && window.FEATURE_FLAGS['form_filler'],
    items: [
      {
        url: '/ops/forms',
        label: 'Forms',
        icon: <DocumentIcon className={linkClasses} />,
        visibleIf: () => canViewOpsPage(),
      },
    ],
  },
}

export type KitLandingLink = {
  name: string
  target: string
  landingPage: string
  icon: React.ReactNode
}
export const KIT_LANDING_PAGE_LINKS: KitLandingLink[] = [
  {
    name: 'SalesRabbit',
    target: '_blank',
    landingPage: 'https://app.lumio.salesrabbit.com',
    icon: (
      <div className="flex h-18 w-18 items-center justify-center rounded-md bg-red-500">
        <SalesRabbitIcon />
      </div>
    ),
  },
  ...(canViewLumioStore()
    ? [
        {
          name: 'Lumio Store',
          target: '_blank',
          landingPage: 'https://store.lumio.com/',
          icon: <LumioDashboardIcon />,
        },
      ]
    : []),
  {
    name: 'Daily Brief',
    target: '_self',
    landingPage: '/daily-brief',
    icon: (
      <div className="flex h-18 w-18 items-center justify-center rounded-md">
        {APPICONS['DailyBrief']}
      </div>
    ),
  },
  ...(canViewWorkdayElements()
    ? [
        {
          name: 'Training',
          target: '_blank',
          landingPage: 'https://www.myworkday.com/lumio/learning/mylearning',
          icon: (
            <img
              src="/images/workday-icon.png?id=1"
              className="flex h-18 w-18 items-center justify-center rounded-md"
              alt="workday icon"
            />
          ),
        },
      ]
    : []),
]
