import React, { Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'

// The fullcalendar +more popover has a z-index of 9999 and the calendar details popover
// has a z-idex of 10000 to beat that, so this needs to be even higher than that for now.
const Z_INDEX_CLASS = 'z-[10001]'
export const POSITION_CLASS = 'items-center justify-center'

export type BaseModalProps = React.ComponentPropsWithoutRef<'div'> & {
  children: React.ReactNode
  modalOpen: boolean
  // To prevent the modal from closing when a user clicks outside of it, don't pass an `onClose`
  // handler to the `BaseModal`. This will disable both closing on outside click and closing via
  // the `esc` key and delegates close functionality to the parent component (e.g. forcing the
  // user to close the modal using close or cancel buttons).
  onClose?: () => void
  blurBackdrop?: boolean
  fullScreenOnMobile?: boolean
  positionClasses?: string
  initialFocus?: React.MutableRefObject<HTMLElement | null>
  zIndexClass?: string
}
/*
 * The `BaseModal` component is intended to serve as a very unopinionated, blank-slate modal that
 * just handles the basic logic for making the modal appear / disappear. It does not impose any
 * layout constraints, add any margin or padding, or even provide a close button by default. If
 * you are looking for a more full-featured modal component, `Modal/index.tsx` has a number of
 * options for rendering a basic informational or form-based modal in our standard design with
 * various buttons, orientations, etc...
 */
export default function BaseModal({
  children,
  modalOpen,
  onClose,
  blurBackdrop = false,
  fullScreenOnMobile = false,
  initialFocus,
  positionClasses = POSITION_CLASS,
  zIndexClass = Z_INDEX_CLASS,
  ...rest
}: BaseModalProps) {
  /**
   * This useState/useEffect combo is for pattern of:
   * `{showSomeModal && <Suspense><Modal modalOpen  />...}`
   * (as opposed to: `<SomeModal modalOpen={showSomeModal} />`)
   * which makes it so you can have all the js for the modal lazy-loaded only
   * when you click to open it. It makes it so the transition
   * still works even if modalOpen is true the very first time it renders.
   **/
  const [show, setShow] = React.useState(false)
  useEffect(() => setShow(modalOpen), [modalOpen])

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className={`relative ${zIndexClass}`}
        onClose={() => {
          onClose?.()
        }}
        initialFocus={initialFocus}
      >
        <div
          className={classNames(
            'fixed flex',
            positionClasses,
            fullScreenOnMobile
              ? 'top-[5.25rem] left-0 right-0 bottom-0 ipad:top-0'
              : 'inset-0'
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              data-testid="modal-backdrop"
              className={classNames(
                'fixed min-h-full bg-black bg-opacity-25',
                blurBackdrop && 'backdrop-blur',
                fullScreenOnMobile
                  ? 'top-[5.25rem] left-0 right-0 bottom-0 ipad:top-0'
                  : 'inset-0'
              )}
              onClick={onClose}
            />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div data-testid="modal" {...rest}>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
