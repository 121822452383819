import * as Sentry from '@sentry/browser'
import loadIntercom from './intercom'
import $ from 'jquery'

import './bootstrap'
import 'bootstrap'
import { renderReactApp } from './react/main'
import { newToast } from './toast-notifications'

if (window.CURRENT_USER) {
  if (
    window.CURRENT_USER.status === 'onboarding' &&
    window.location.pathname !== '/onboarding'
  ) {
    window.location.replace('/onboarding')
  }

  loadIntercom(window.CURRENT_USER)
  Sentry.setUser({
    id: window.CURRENT_USER.id?.toString(),
    email: window.CURRENT_USER.email,
    role: window.CURRENT_USER.role,
    status: window.CURRENT_USER.status,
    ip_address: undefined,
  })
}

const exp = (window.ENV.SESSION_EXPIRES_AT ?? 0) * 1000
if (exp > Date.now()) {
  setTimeout(() => {
    newToast('notice', 'Session expired. Reloading page...')
    setTimeout(() => {
      location.reload()
    }, 2500)
  }, exp - Date.now())
}

$('.application').on('click', '#logout', function triggerLogout(event) {
  event.preventDefault()
  $(this).closest('form').trigger('submit')
})

renderReactApp()

// checking if react-sidebar exists to decide to run the stuff in common is kinda funky but it's relic
// of the past and a proxy of "are we on a full app page that needs all that old jquery stuff to run?"
if (document.getElementById('react-sidebar')) import('./routes/common')
