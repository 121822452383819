import classNames from 'classnames'
import React from 'react'
import { Dialog } from '@headlessui/react'
import { ChevronLeftIcon, XIcon } from '@heroicons/react/outline'

import Button from '../Button'
import BaseModal from '../BaseModal'
import IconButton from '../IconButton'

const SquareXIcon = () => <XIcon className="h-6 w-6" />
const BackIcon = () => <ChevronLeftIcon className="h-4 w-4 font-bold" />

const MAX_WIDTH = {
  small: 'max-w-lg',
  medium: 'max-w-2xl',
  large: 'max-w-4xl',
}

type ModalType = {
  modalOpen: boolean
  title?: React.ReactNode
  body: React.ReactNode
  titleClasses?: string
  className?: string
  paddingClasses?: string
  positionClasses?: string
  colorButtonConfirm?:
    | 'primary'
    | 'secondary'
    | 'complete'
    | 'error'
    | 'success'
    | 'link'
  variantButtonConfirm?: 'solid' | 'outline' | 'bare'
  textConfirm?: string
  textCancel?: string
  orientationButtons?: 'horizontal' | 'vertical'
  allowBackDropClick?: boolean
  onConfirm?: () => boolean | Promise<boolean>
  onCancel?: (args?: any) => void
  onClose?: (args?: any) => void
  closeButton?: React.ReactElement
  hideCloseButton?: boolean
  confirmButton?: React.ReactNode
  hideConfirmButton?: boolean
  disableConfirmButton?: boolean
  cancelButton?: React.ReactNode
  hideCancelButton?: boolean
  buttons?: React.ReactNode
  initialFocus?: React.MutableRefObject<HTMLElement | null>
  isLoading?: boolean
  fullScreenOnMobile?: boolean
  modalWidth?: keyof typeof MAX_WIDTH
  footerClassName?: string
  variantButtonCancel?: 'solid' | 'outline' | 'bare'
  enableBackButton?: boolean
}

const MAP_ORIENTATION_BUTTON = {
  horizontal: 'flex-row-reverse',
  vertical: 'flex-col items-center',
}

const Modal = ({
  modalOpen,
  title,
  body,
  titleClasses,
  className,
  paddingClasses = 'p-6 px-20',
  positionClasses,
  textConfirm = 'Yes!',
  textCancel = 'Cancel',
  colorButtonConfirm = 'success',
  variantButtonConfirm = 'solid',
  orientationButtons = 'vertical',
  allowBackDropClick = true,
  onConfirm,
  onCancel,
  onClose,
  closeButton,
  hideCloseButton,
  confirmButton,
  hideConfirmButton = false,
  disableConfirmButton = false,
  cancelButton,
  hideCancelButton = false,
  buttons,
  initialFocus,
  isLoading = false,
  fullScreenOnMobile = false,
  modalWidth = 'medium',
  footerClassName,
  variantButtonCancel = 'bare',
  enableBackButton = false,
}: ModalType) => {
  const runFunctionOnClose = () => {
    onClose?.()
  }

  const runFunctions = async () => {
    const result = await onConfirm?.()
    if (result === true) {
      runFunctionOnClose()
    }
  }

  const runFunctionOnCancel = () => {
    onCancel?.()
  }

  const confirmActions = () => [
    hideConfirmButton ? undefined : confirmButton ? (
      <React.Fragment key="btn-confirm-modal">{confirmButton}</React.Fragment>
    ) : (
      <Button
        key="btn-confirm-modal"
        onClick={() => runFunctions()}
        color={colorButtonConfirm}
        className="text-extrabold block w-fit text-lg"
        size="large"
        variant={variantButtonConfirm}
        disabled={isLoading || disableConfirmButton}
        loading={isLoading}
      >
        {textConfirm}
      </Button>
    ),
    hideCancelButton ? undefined : cancelButton ? (
      <React.Fragment key="btn-cancel-modal">{cancelButton}</React.Fragment>
    ) : (
      <Button
        key="btn-cancel-modal"
        onClick={() => runFunctionOnCancel()}
        color="primary"
        variant={variantButtonCancel}
        className="block w-fit"
        size="large"
        disabled={isLoading}
      >
        {textCancel}
      </Button>
    ),
  ]

  const getHeader = () => {
    const elements = []
    if (enableBackButton) {
      elements.push(
        <IconButton
          key="back-button"
          icon={BackIcon}
          description="Close Modal"
          size="medium"
          onClick={() => {
            runFunctionOnCancel()
            runFunctionOnClose()
          }}
          variant="bare"
          className="absolute top-7 left-3 !outline-vader hover:!bg-atat/30"
        />
      )
    }
    if (title) {
      elements.push(
        <Dialog.Title
          key="title"
          as="h3"
          className={classNames(
            'my-2 text-left text-2xl font-semibold',
            titleClasses,
            enableBackButton ? 'ml-6' : ''
          )}
        >
          {title}
        </Dialog.Title>
      )
    }

    if (!hideCloseButton) {
      if (closeButton) {
        elements.push(
          React.cloneElement(closeButton, {
            key: 'close-button',
            onClick: () => {
              runFunctionOnCancel()
              runFunctionOnClose()
            },
          })
        )
      } else {
        elements.push(
          <IconButton
            key="close-button"
            icon={SquareXIcon}
            description="Close Modal"
            size="medium"
            onClick={() => {
              runFunctionOnCancel()
              runFunctionOnClose()
            }}
            variant="bare"
            className="absolute top-6 right-6 !outline-vader hover:!bg-atat/30"
          />
        )
      }
    }

    if (elements.length > 0) return <div>{elements}</div>
  }

  const getFooter = () => {
    if (buttons) return buttons
    if (hideConfirmButton && hideCancelButton) return

    return (
      <div
        className={classNames(
          'flex gap-3',
          MAP_ORIENTATION_BUTTON[orientationButtons],
          footerClassName
        )}
      >
        {confirmActions()}
      </div>
    )
  }

  const modalClasses = classNames(
    'flex flex-col gap-6 w-full transform rounded-2xl bg-snowtrooper text-left shadow-xl transition-all overflow-y-auto max-h-screen',
    MAX_WIDTH[modalWidth],
    paddingClasses,
    className
  )

  return (
    <BaseModal
      modalOpen={modalOpen}
      onClose={allowBackDropClick ? runFunctionOnClose : undefined}
      className={modalClasses}
      initialFocus={initialFocus}
      positionClasses={positionClasses}
      fullScreenOnMobile={fullScreenOnMobile}
    >
      <>
        {getHeader()}
        {body}
        {getFooter()}
      </>
    </BaseModal>
  )
}

export default Modal
